<template>
  <div>
    <el-header class="header">
      <nav class="nav">
        <h1 class="logo_wrap">
          <a :href="jumpLink"><img src="~/assets/img/header/logo.png" alt="企业社保代缴,电子工资条,薪资代发" title="壹人事"/></a>
        </h1>
        <div class="wrap_5th">
          <i></i>
          <img src="~/assets/img/header/icon_5th.png" alt="5周年" />
        </div>
        <ul class="navBar">
          <li class="home">
            <a class="transi_all" :href="jumpLink"><span>首页</span></a>
          </li>
          <li class="product">
            <a class="transi_all" :href="jumpLink+'/productsservices'" rel="nofollow">
              <span>产品与服务</span>
              <img style="margin-left:2px;" class="transi-all" src="~/assets/img/header/down-arrow.svg" alt="壹人事-产品" />
            </a>
             <div class="product_down" style="display:none;">
                <div class="product_box">                 
                  <div class="product_list">
                      <p class="product_title">在线HR软件</p>
                      <div class="product_list_box">
                       <a class="product_item nav-pro-roster" :href="jumpLink+'/yuangonghuamingce.html'">
                          <img src="~/assets/img/header/nav_roster.png" alt="员工花名册" />
                          <div class="item_top">
                            <h2 class="item_title">员工花名册</h2>
                            <p class="item_text">云端花名册，自动提醒，自动分析图表</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-learning" :href="jumpLink+'/employeelearning.html'">
                          <img src="~/assets/img/header/nav_learning.png" alt="员工学习" />
                          <div class="item_top">
                              <h2 class="item_title">员工学习</h2>
                              <p class="item_text">不限培训场景，员工随时学习</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-salary" :href="jumpLink+'/gongzitiao.html'">
                          <img src="~/assets/img/header/nav_salary.png" alt="电子工资条" />
                          <div class="item_top">
                            <h2 class="item_title">电子工资条<img class="hot_icon" src="~/assets/img/header/icon_hot.png" /></h2>
                            <p class="item_text">多端群发，到达30+国家地区，员工签名确认</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-attendanc" :href="jumpLink+'/yidongkaoqin.html'">
                        <img src="~/assets/img/header/nav_attendanc.png" alt="移动考勤" />
                        <div class="item_top">
                          <h2 class="item_title">移动考勤</h2>
                          <p class="item_text">多场景考勤需求，一键导出考勤数据</p>
                        </div>
                      </a>
                      <a class="product_item nav-pro-entry" :href="jumpLink+'/zhinengruzhi.html'">
                          <img src="~/assets/img/header/nav_entry.png" alt="智能入职" />
                          <div class="item_top">
                            <h2 class="item_title">智能入职</h2>
                            <p class="item_text">一键发送Offer，小程序极速办理入职</p>
                          </div>
                      </a>
                     </div>
                  </div>
                  <div class="product_list">
                      <p class="product_title">员工福利平台</p>
                      <div class="product_list_box">
                        <a class="product_item nav-pro-holiday" :href="jumpLink+'/welfare.html'">
                          <img src="~/assets/img/header/nav_welfare.png" alt="节日福利" />
                          <div class="item_top">
                            <h2 class="item_title">节日福利</h2>
                            <p class="item_text">14年福利经验，企业在线发放，员工寄送到家</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-integral" :href="jumpLink+'/jifenfuli.html'">
                          <img src="~/assets/img/header/nav_integral.png" alt="积分福利" />
                          <div class="item_top">
                            <h2 class="item_title">积分福利<img class="hot_icon" src="~/assets/img/header/icon_hot.png" /></h2>
                            <p class="item_text">弹性员工福利，多场景，双节税</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-physicalexam" :href="jumpLink+'/yuangongtijian.html'">
                          <img src="~/assets/img/header/nav_physicalexam.png" alt="员工体检" />
                          <div class="item_top">
                            <h2 class="item_title">员工体检</h2>
                            <p class="item_text">一站式企业员工体检，多区域覆盖，多套餐可选</p>
                          </div>
                      </a>
                      </div>
                  </div>
                   <div class="product_list">
                    <p class="product_title">在线人事外包</p>
                    <div class="product_list_box">
                    <a class="product_item nav-pro-deposit" :href="jumpLink+'/depositservice.html'">
                        <img src="~/assets/img/header/nav_deposit.png" alt="社保账户托管" />
                        <div class="item_top">
                          <h2 class="item_title">社保账户托管</h2>
                          <p class="item_text">企业账户托管，在线提交增减员</p>
                        </div>
                    </a>
                    </div>
                  </div>
                </div>
              </div>
          </li>
          <li class="active"><a href="/" rel="nofollow"><span>企业商城</span></a></li>
          <li class="info">
            <a class="transi-all" :href="jumpLink + '/newslist.html'"><span>政策与支持</span></a>
          </li>
          <li v-if="false">
            <a class="transi-all" :href="communityLink" rel="nofollow"><span>社区资讯</span></a>
          </li>          
          <li class="about-person">
            <a class="transi-all" href="" rel="nofollow">
              <span>关于壹人事</span>
              <img class="transi-all" src="~/assets/img/header/down-arrow.svg" alt="壹人事-产品">
            </a>
            <div class="about-person-down">
              <div class="about-person-down-content">
                <a class="item" :href="jumpLink+'/aboutus.html'">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person1.png" />
                      <span>壹人事介绍</span>
                    </div>
                    <p class="msg">一站式企业人事服务云平台，20年经验110+城市 ></p>
                </a>
                <a class="item" href="https://www.echatsoft.com/visitor/pc/chat.html?companyId=12203&echatTag=website">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person2.png" />
                      <span>在线客服</span>
                    </div>
                    <p class="msg">立体客户服务体系，立即接入人工坐席 ></p>
                </a>
                <a class="item" :href="jumpLink+'/solution.html'">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person3.png" />
                      <span>客户案例</span>
                    </div>
                    <p class="msg">2.6万+企业，成功推动客户人事管理提效 ></p>
                </a>
                <a class="item" :href="jumpLink+'/shebaocesuan.html'">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person4.png" />
                      <span>计算工具</span>
                    </div>
                    <p class="msg">五险一金/工资个税/养老金/延迟退休计算，一秒出结果 ></p>
                </a>
                <a class="item" :href="jumpLink+'/helpCenter.html'">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person5.png" />
                      <span>帮助中心</span>
                    </div>
                    <p class="msg">把您的人事问题告诉我们，即刻为您解答 ></p>
                </a>
                <a class="item" :href="jumpLink+'/noviceschool.html'">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person7.png" />
                      <span>新手学堂</span>
                    </div>
                    <p class="msg">1分钟视频，真实系统演示，HR快速上手 ></p>
                </a>
                <a class="item" :href="jumpLink+'/eyzjappdownload.html'">
                    <div class="item-title-box">
                      <img src="~/assets/img/header/about-person8.png" />
                      <span>员工端APP下载</span>
                    </div>
                    <p class="msg">专为企业员工打造的服务app，福利自主兑换 ></p>
                </a>
              </div>
            </div>
          </li>
        </ul>
        <!-- 全局搜索 -->
        <div class="search">
            <div id="swiperWordBox" class="swiper_wordbox">
              <div id="swiperWordList" class="swiper_wordlist"></div>
            </div>
            <input id="searchIpt" v-model="searchIpt" class="search_ipt" type="text" maxlength="30" placeholder="" autocomplete="off" @keyup.enter.native="searchBtn" @input="searchInput()" @focus="searchFocus()"/>
            <i class="icon_search" @click="searchBtn"></i>
            <div id="searchResultBox" class="search_result" style="display:none;">
              <ul class="result_box"></ul>
            </div>
            <div id="hotSearchBox" class="hot_search" style="display:none;">
              <ul class="hot_list"></ul>
            </div>
        </div>
        <!-- 登录 -->
        <div class="login-wrap">
            <div class="go-register" v-if="!userData.privateUserID" @click="openLoginDialogCom">企业工作台</div>
            <div class="login-after login" v-if="userData.privateUserID">
              <a
                id="workbench" class="go-register transi-all radius-20"
                style="margin-top:16px;margin-right:20px;"
                @click="pushWorkbench(userData)"
                rel="nofollow">企业工作台
              </a>
              <a class="user-img transi-all"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <img id="chouti-img" class="img"
                  :src="userData.privateUserInfo.privatePhotoUrl?userData.privateUserInfo.privatePhotoUrl:defaultImg"
                />
              </a>
              <a id="chouti_name" class="name"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">{{userData.privateUserInfo.nickName}}</a>
            </div>
            <div v-else class="nrb-no-login-box">
                <img class="nrb-item-icon" src="~/assets/img/header/user.png" alt="登录/注册" @click="openLoginDialogCom" />
                <span class="nrb-no-login-text header-login-in" @click="openLoginDialogCom">登录 | 注册</span>
                <i class="nrb-no-login-send20"></i>
            </div>
            <div class="signing_box" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <a :href="jumpLink+'/UserCenter/Index'">用户中心</a>
                <a @click="pushWorkbench(userData)">进入工作台</a>
                <a :href="jumpLink+'/UserCenter/UserTask'">壹豆获取<i class="red_spot"></i></a>
                <a :href="jumpLink+'/UserCenter/UserAccountSetting'">账号设置</a>
                <a @click="loginOut">退出登录</a>
              </div>
          </div>
      </nav>
    </el-header>
    <Drawer />
    <WebSideWorker />
  </div>
</template>
<script>
import $ from "jquery";
import Drawer from "./userDrawer.vue";
import WebSideWorker from "./websideworker";
import { getReqUserInfo, getFuzzyQuery, getHotSearch } from "network/welfareMall_api.js";
import { mapState } from "vuex";

export default {
  middleware: "layout",
  components: {
    Drawer,
    WebSideWorker,
  },
  data(){
    return{
      userData:{},
      jumpLink: this.preUrlHome,
      jumpLoginLink: this.preUrlOld,
      communityLink: this.communityUrl,
      defaultImg: require("../assets/img/header/tx.png"), //用户默认头像
      hotsearchIcon: require("../assets/img/header/hot_search.png"), //热门词组icon
      searchTimer:null,
      swiperTimer:null,
      swiperIndex:0,// 搜索轮播词的当前轮播下标
      searchIpt:'',//搜索框
    }
  },
  watch:{
    monitor() {
      if (this.$store.state.useInfo) {
        this.userData = this.$store.state.useInfo;
      }
    },
  },
  computed: {
    // 映射state
    ...mapState(["useInfo", "companyInfo", "cartNum"]),
    monitor() {
      return this.$store.state.useInfo;
    },
  },
  mounted(){
    let _this = this;
    // 产品与服务
    $('.nav .navBar>li.product').mouseenter(function () {
      $('.product_down').show().slideDown('fast');
    })
    $('.nav .navBar > li.product').mouseleave(function () {
      $('.product_down').hide().slideUp('fast');
    })
    // 关于壹人事
    $('.about-person').mouseenter(function () {
      $('.about-person-down').show().slideDown('fast');
    })
    $('.about-person').mouseleave(function () {
      $('.about-person-down').hide().slideUp('fast');
    })

    // 监听是否点击在输入框以外区域
    document.addEventListener("click", (e) => {
        let searchBoxRow1 = document.getElementById("searchIpt");
        if (!searchBoxRow1.contains(e.target)) {
            $('#searchResultBox').hide();
            $('#hotSearchBox').hide();
            if (!_this.searchIpt) {
                $('#swiperWordBox').show();
            }
        }
    })
    _this.getHotSearchData();//获取热门搜索词
  },
  created(){
    this.getSearchSwiper();//搜索框轮播处理
  },
  methods:{
    //搜索框轮播处理
    async getSearchSwiper(){
      let _this = this;
      let res = await getHotSearch(1);
      if(res.IsSuccess){
        let data = res.Data;
        let swiperItemLen = data.length;
        let searchSwiperHtml = '';
        for (let i = 0; i < data.length; i++) {
            searchSwiperHtml += `<div class="swiper_worditem ellipsis swi${i}">${data[i].Name}</div>`;
        }
        if (swiperItemLen) {
            searchSwiperHtml += `<div class="swiper_worditem ellipsis swi${0}">${data[0].Name}</div>`;
        }
        document.getElementById('swiperWordList').innerHTML = searchSwiperHtml;
        // 轮播
        let swiperList = document.getElementById('swiperWordList');
        let speed = 1500; // 移动速度
        let boxHeight = 30; // 列表高度
        function marquee() {
            if (!swiperItemLen) return
            if (swiperList.offsetTop <= -(boxHeight * swiperItemLen)) {
                swiperList.style.transition = 'none';
                swiperList.style.top = '0px';
                _this.swiperIndex = 0;
                marquee()
            } else {
                swiperList.style.transition = 'all 0.5s ease-in-out';
                swiperList.style.top = (swiperList.offsetTop - boxHeight) + 'px';
                _this.swiperIndex = -(swiperList.offsetTop - boxHeight) / boxHeight;
            }
        }
        _this.swiperTimer = setInterval(marquee, speed);
      }
    },
    //获取热门搜索词
    async getHotSearchData(){
      let res = await getHotSearch(2);
      let reslutData = res.Data;
      let resultListStr = "";
      // console.log(reslutData)
      if(reslutData!=''&& reslutData!= null){
        resultListStr += `<div class="hot_header">热门搜索<img src="${this.hotsearchIcon}"></div>`;
        for (let i = 0; i < 5; i++) {
          resultListStr += `<li><a href="${this.jumpLink}/globalsearch.html?search=${reslutData[i].Name}">${reslutData[i].Name}</a></li>`;
        }
        $('.hot_list').html(resultListStr);
      }
    },
    debounce(fn, delay = 100) {
      clearTimeout(this.searchTimer)
      this.searchTimer= setTimeout(fn, delay)
    },
    // 搜索输入框input事件
    searchInput() {
      this.getSearchValue();
      if(this.searchIpt&&this.searchIpt.trim() !== '') {
        this.debounce(this.searchNormal);
      }
    },
    // 搜索输入框focus事件
    searchFocus() {
      this.getSearchValue();
      if(this.searchIpt&&this.searchIpt.trim() !== '') {
        this.debounce(this.searchNormal);
      }
    },
    getSearchValue() {
      $('#swiperWordBox').hide();
      if (this.searchIpt&&this.searchIpt.trim() !== '') {
        $('#searchResultBox').show();
        $('#hotSearchBox').hide();
      } else {
        $('#searchResultBox').hide();
        $('#hotSearchBox').show();
      }
    },
    searchBtn() {
      let _this = this;
      let iptVal = '';
      let isHiddenRotationphrase = $('#hotSearchBox').is(':hidden');
      let isHiddenNormal = $('#searchResultBox').is(':hidden');
      // 输入框没有点击时,搜索当前轮播词
      if (!_this.searchIpt && isHiddenRotationphrase && isHiddenNormal) {
        iptVal = $('.swi' + _this.swiperIndex).text();
      }
      if (_this.searchIpt) {
        iptVal = _this.searchIpt;
      }
      window.location.href = _this.jumpLink+'/globalsearch.html?search=' + iptVal;
    },
    //输入后进行搜索关键字联想
    async searchNormal() {
      let data = await getFuzzyQuery(this.searchIpt);
      let resultListStr='';
      if(data !=''&& data !=null){
        let resNew = data.slice(0, 5);
        for(let i=0;i<resNew.length;i++){
          resultListStr += `<li><a href="${this.jumpLoginLink}/globalsearch.html?search=${resNew[i].Title.replace(/<[^>]*>/g, '') }">
            ${resNew[i].Title}</a> </li>`;
        }
        $('.result_box').html(resultListStr);
        $('#searchResultBox').show();
        $('#hotSearchBox').hide();
      }else{
        $('.result_box').html('');
        $('#searchResultBox').hide();
        $('#hotSearchBox').show();
      }
    },
    // 登录
    openLoginDialogCom() {
      this.$bus.$emit("openLoginDialogCom");
    },
    // 用户中心
    handleMouseEnter() {
      $(".signing_box").show();
    },
    handleMouseLeave() {
      $(".signing_box").hide();
    },
    //点击工作台
    async pushWorkbench() {
      let pctoken = this.GetTokenFromCookie("pcToken");
      if (!pctoken) { return;}
      let res = await getReqUserInfo();
      if (res.code == 200) {
        let userDataList = res.data;
        if (userDataList.companyList.length == 0) {
          this.$store.commit("openAddCompany");
        } else {
          var userID = userDataList.companyList[0].userID;
          location.href = this.jumpLoginLink + "/Account/Login?token=" + this.GetTokenFromCookie("pcToken") + "&userId=" + userID;
        }
      } else {
        this.$message.warning(res.msg);
        this.loginOut();
      }
    },
    //点击退出
    loginOut() {
      let _this = this;
      $.ajax({
        url: _this.jumpLoginLink + "/Account/LogoutOffice?client=officesite",
        type: "get",
        timeout: 5000,
        dataType: "JSONP", // 处理Ajax跨域问题
        data: { callback: "?" },
        // 默认情况下，标准的跨域请求是不会发送cookie的
        xhrFields: {
          withCredentials: true,
        },
        success: function () {
          $.cookie("pcToken", "", { expires: -1 });
          $.cookie("taskJumpId", "", { expires: -1 });
          _this.delCookie();
          localStorage.removeItem("tokenExpiration");
          localStorage.removeItem("tokenClient");
          console.log($.cookie("pcToken"), 11122);
          window.location.href = _this.preUrlHome;
        },
      });
    },
    // 删除cookie
    delCookie() {
      let cookies = document.cookie.split(";");
      cookies.forEach(function (cookie) {
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      });
    },
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}
.el-header{
  width: 1200px;
  min-height: 70px;
}
.header {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  .transi-all {
    transition: all 0.2s ease-in;
  }
  .nav{
    display: flex;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    /* LOGO 开始 */
    .logo_wrap{
      width: 120px;
      font-weight: normal;
      .logo{
        margin-right: 10px;
      }
      img{
        max-width: 100%;
        margin-top: 16px;
      }
    }
    /* 五周年IMG */
    .wrap_5th{
      margin-left: 10px;
      i{
        position: relative;
        top: -15px;
        display: inline-block;
        width: 1px;
        height: 30px;
        background: #EEE;
      }
      img{
        margin-top: 6px;
        margin-left: 15px;
        width: 58px;
        height: 54px;
        background-size: cover;
      }
    }
    .navBar{
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      line-height: 70px;
      padding: 0 26px 0 64px;
      li{
        position: relative;
        height: 100%;
        cursor: pointer;
        box-shadow: 0 0 1px rgba(0,0,0,0);
        a{
          font-size: 16px;
          color: #3C3C3C;
          img{
            margin-left: 5px;
          }
        }
        &:hover>a{
          color: #0089CD;
          img{
            transform: rotateX(-180deg);
          }
        }
        &.active a span{
          padding-bottom: 4px;
          color: #0089CD;
          border-bottom: 2px solid #0089CD;
        }
      }
      /* 产品与服务 */
      .product_down{
        position: fixed;
        left: 0;
        width: 100%;
        height: auto;
        background: #fff;
        margin-top: -2px;
        cursor: default;
        box-shadow: 0px 3px 6px 1px rgba(152, 152, 152, 0.1);
        .product_box {
          width: 1200px;
          margin: 0 auto;
          padding-top: 35px;
          padding-bottom: 35px;
          .product_list {
            float: left;
            display: inline-block;
            width: 280px;
            margin-left: 50px;
            &:nth-child(1){
              margin-left: 0;
            }
            &:nth-child(1){
              width: 540px;
              .product_title{
                width: 540px;
              }
            }
            .product_list_box{
                width: 260px;
                height: 245px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
            .product_title {
              width: 260px;
              text-align: left;
              font-size: 18px;
              font-weight: 500;
              color: #3c3c3c;
              line-height: 24px;
              padding-bottom: 20px;
              border-bottom: 1px solid #E8E8E8;
              margin-bottom: 20px;
              margin-left: 10px;
            }
            .product_item {
              display: inline-block;
              width:260px;
              margin-bottom: 10px;
              &:hover{
                background: #f5f6f7;
                border-radius: 4px;
              }
              img{
                display: inline-block;
                float: left;
                width: 30px;
                height: 30px;
                background-size: 100%;
                margin: 12px 10px 0 10px;
              }
              .item_top {
                padding-top: 12px;
                padding-bottom: 12px;
                .item_title {
                  font-size: 16px;
                  color: #3c3c3c;
                  line-height: 16px;
                  padding-bottom: 4px;
                  .hot_icon {
                    display: inline-block;
                    width: 30px;
                    height: 15px;
                    margin: 0;
                    position: relative;
                    float: none;
                    margin-left: 10px;
                  }
                }
                .item_text {
                  font-size: 12px;
                  color: #9a9a9a;
                  line-height: 16px;
                  padding-left: 50px;
                }
              }
            }
          }
        }
      }
      /* 关于壹人事 */
      .about-person-down {
        display: none;
        position: absolute;
        top: 70px;
        left: -215px;
        width: 635px;
        height: 330px;
        background: #FFFFFF;
        box-shadow: 0px 3px 12px 1px rgba(152,152,152,0.15);
        border-radius: 0px 0px 20px 20px;
        border: 1px solid #E9E9E9;
        line-height:normal;
        .about-person-down-content{
          padding: 16px 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item{
            margin-bottom: 8px;
            padding: 8px 10px;
            width: 280px;
            height: 70px;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            &:hover{
              background: #F5F6F7;
            }
            .item-title-box{
              img{
                width: 30px;
                height: 30px;
                vertical-align: -6px;
              }
              span{
                margin-left: 5px;
                font-size: 16px;
                color: #3C3C3C;
              }
            }
            .msg{
              margin-top: 8px;
              font-size: 12px;
              color: #9A9A9A;
            }
          }
        }
      }      
    }
    /* 全局搜索 */
    .search{
      position:relative;
      width: 190px;
      height:40px;
      margin:15px 20px 15px 0;
      /* 热词轮播 */
      .swiper_wordbox {
          position: absolute;
          top: 5px;
          left: 0px;
          width: 158px;
          height: 30px;
          overflow: hidden;
          .swiper_wordlist {
            position: absolute;
            top: 0;
            left: 0;
            /deep/.swiper_worditem {
              padding-left: 15px;
              width: 158px;
              height: 30px;
              line-height: 30px;
              box-sizing: border-box;
              color: #8B8C9A;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
          }
        }
      }
      input{
        width: 190px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        padding-left: 10px;
        padding-right: 36px;
        box-sizing: border-box;
        position: absolute;
        background-color: transparent;
        &:focus{
          border: 1px solid #0089cd !important;
        }
      }
      i.icon_search{
        cursor: pointer;
        position:absolute;
        top:10px;
        right:15px;
        width: 18px;
        height: 18px;
        background: url('../assets/img/header/sousuo.png') no-repeat;
        &:hover{
          background: url('../assets/img/header/sousuo_hover.png') no-repeat;
        }
      }
    }
    /* 搜索结果显示 */
    .search_result{
      position: absolute;
      top: 40px;
      height: auto !important;
      background: #fff;
      width: 334px;
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      // @media screen and (max-width: 1980px) {
      //   left: 56% !important;
      // }
      @media screen and (max-width: 1440px) {
        left: 59% !important;
      }
      @media screen and (max-width: 1200px) {
        left: 710px !important;
      }
      /deep/ .result_box{
        padding: 25px;
        li{
          margin-bottom: 20px;
          cursor: pointer;
          display: -webkit-box;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          a{
            font-size: 16px;
            color: #3c3c3c;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .hot_search{
      position: absolute;
      top: 40px;
      height: auto !important;
      background: #fff;
      width: 334px;
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      // @media screen and (max-width: 1980px) {
      //   left: 56% !important;
      // }
      @media screen and (max-width: 1440px) {
        left: 59% !important;
      }
      @media screen and (max-width: 1200px) {
        left: 710px !important;
      }
      /deep/.hot_list{
        width: 334px;
        padding: 10px;
        .hot_header{
          margin-top: 5px;
          padding-left: 10px;
          padding-bottom: 15px;
          font-size: 16px;
          font-weight: bold;
          color: #3c3c3c;
          border-bottom: 1px solid #EAEAEA;
          img{
            margin-left: 7px;
          }
        }
        li{
          margin: 10px 10px 20px;
          a{
            font-weight: 400;
            line-height: 16px;
            color: #0089cd;
          }
          &:nth-child(2){
            margin-top: 15px;
          }
          &:last-child{
            margin-bottom: 10px;
          }
        }
      }
    }
    /* 登录注册 账号中心 */
    .login-wrap{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      width: 280px;
      color: #3c3c3c;
      font-size: 16px;
      a{
        display: inline-block;
        &.name{
          width: 80px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-left: 5px;
          cursor: pointer;
        }
      }
      .go-register{
        cursor: pointer;
        display: inline-block;
        height: 36px;
        line-height: 18px;
        padding: 8px 13px;
        font-size: 16px;
        font-weight: bold;
        color:#5688FF;
        border:1px solid #5688FF;
        border-radius: 18px;
      }
      .nrb-no-login-box{
        margin-left: 30px;
        position: relative;
        .nrb-item-icon{
          width: 24px;
          height: 24px;
          cursor: pointer;
          vertical-align: middle;
        }
        .nrb-no-login-text{
          margin-left: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #3c3c3c;
          cursor: pointer;
        }
        .nrb-no-login-send20{
          position: absolute;
          right: -47px;
          top: -15px;
          display: inline-block;
          width: 80px;
          height: 18px;
          background: url("../assets/img/header/send120.png") no-repeat;
          background-size: 100%;
        }
      }
      .login-after {
        display: flex;
        height: 70px;
        line-height: 70px;
        .user-img {
          display: inline-block;
          width: 32px;
          height: 32px;
          margin-top: 8px;
          cursor: pointer;
          .img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
      .signing_box {
        position: fixed;
        top: 62px;
        display: none;
        width: 139px;
        height: 205px;
        padding-top: 12px;
        margin-top: -16px;
        margin-left: 195px;
        background: url(../assets/img/header/signing_box.png);
        a{
          display: block;
          width: 130px;
          height: 37px;
          line-height: 37px;
          text-align: center;
          margin: 0 auto;
          color: #3c3c3c;
          &:hover {
            cursor: pointer;
            background: #f5f5f5;
          }
          .red_spot {
            position: relative;
            top: -10px;
            right: -6px;
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #f25130;
          }
        }
      }
    }
  }
}
</style>
import { data, param } from "jquery";
import { request } from "./request";
// 判断官网和HR端当前环境    获取老项目基准地址
let apiUrl = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://api.ers-content.test-01.54test.cn/api";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://ersapi.1renshi.com/api";
    } else {
        return "https://api.ers-content.test-01.54test.cn/api";
    }
}()
// 判断官网和HR端当前环境  员工体检接口地址
let healthApi = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://api.ers-healthcheck.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://api.healthcheck.1renshi.com";
    } else {
        return "https://api.ers-healthcheck.test-01.54test.cn";
    }
}()
// 用户协议弹窗按钮点击支付订单
export const getReqSaveCustomer = () => request({
    url: `/Welfare/Welfare/SaveCustomerData`,
    method: 'post',
})

// 跳转法大大的接口
export const getReqCompanyVerify = () => request({
    url: `/Contract/CompanyVerify`,
    method: 'get',
})

// // 判断是否已认证  （法大大）
export const getReqCompanyCertInfo = () => request({
    url: `/Contract/CompanyCertInfo`,
    method: 'get',
})
// // 判断是否已认证  （简易认证）
export const getCompanyCertOrSimpleInfo = () => request({
    url: `/Contract/CompanyCertOrSimpleInfo`,
    method: 'get',
})

// 购物车列表
export const getReqMyCart = () => request({
    method: 'get',
    url: `/Welfare/MyCart/List`
})

//购物车的加减
export const getReqCountChange = (params) => request({
    method: 'get',
    url: `/Welfare/MyCart/CountChange`,
    params
})

// 提交订单
export const getReqOrder = (params) => request({
    method: 'post',
    url: `/Welfare/Order/Add`,
    params
})

// 获取首页Banner
export const getReqHomeBanner = () => request({
    method: 'get',
    url: '/Common/Banner/GetBannerList/10'
})

// 获取search商品或礼包列表
export const getReqGoodAndMealList = (params)=>request({
    method: 'get',
    url: `/Welfare/Mall/GoodsAndMeal/List/${params.name}`,
    params: {
        pageIndex: params.pageIndex,
        limit: params.limit,
    }
})

// 获取礼包详情
export const getReqMealDetails = (id)=>request({
    method: 'get',
    url: `/Welfare/Shop/MealGoodInfo`,
    params: {
        mealId: id
    }
})

// 获取用户信息
export const getReqUserInfo = ()=>request({
    method: 'get',
    url: '/User/LoginUser'
})
// 获取商品详情
export const getReqGoodInfo = (commodityID)=>request({
    method: 'get',
    url: `/Welfare/Shop/GoodInfo/${commodityID }`,
})

// 获取礼包列表
export const getReqMealList = ()=>request({
    method: 'get',
    url: `/Welfare/Mall/Meal/List`,
})

// 点击更多 获取全部的礼包列表
export const getReqMealListAll = (id)=>request({
    method: 'get',
    url: `/Welfare/Mall/SearchByFestival/MealList`,
    params:{
        festivalId: id
    }
})

//根据搜索条件获取礼包列表(福利商城)
export const getReqSearchName = (params)=>request({
    method: 'get',
    url: `/Welfare/Mall/Meal/List/${params.mealName}`,
    params:{
        pageIndex: params.pageIndex,
        limit: params.limit,
    }
})

// 加入购物车 此接口登录那一刻只调用一次
export const getReqAddCart = (data)=>request({
    method: 'post',
    url: '/Welfare/Mall/MyCart/Add',
    data
})

// 加入购物车 登录状态
export const getReqLoginAddCart = (params)=>request({
    method: 'get',
    url: `/Welfare/MyCart/Add`,
    params
})

// 获取登录时的购物车数量
export const getReqCartNum = ()=>request({
    method: 'get',
    url: '/Welfare/MyCart/CartNum'
})

// 点击去认证 (获取toke)
export const getReqGoAuth = (userID)=>request({
    method: 'post',
    url: `/Auth/Login/Company?userId=${userID}&terminalType=1`
})

// 点击去认证 (获取跳转地址)
export const getReqGoAuthUrl = ()=>request({
     method: 'get',
     url: `/Contract/CompanyVerify`
 })

// 获取政策与支持
export const getHeadCategoryList = () => request({
    method: 'get',
    url:`${apiUrl}/ArticleCategory/GetArticleCategoryData?type=1`,
})
// 全局搜索 模糊查询
export const getFuzzyQuery = (search) => request({
    method: 'get',
    url:`${apiUrl}/Common/GetTopRelatedArticlesAsync?KeyWord=${search}`
})
// 全局搜索 热门词组Common
export const getHotSearch = (type) => request({
    method: 'get',
    url:`${apiUrl}/HelpCenterQuestion/GetEnableHotWordList?getType=${type}`
})
//获取悬浮窗信息
export const getSuspensionContent = () => request({
    method: 'get',
    url:`${apiUrl}/Banner/GetAppAdsensesImg?type=25`
})
//获取商城商品类目列表（默认商品最多的8个）
export const getProductList = (number) => request({
    method:'get',
    url:`/Welfare/Mall/Category/List/${number}`
})
//获取商城商品类目下的商品列表
export const getProductListCommodity = (params) => request({
    method:'get',
    url:`/Welfare/Mall/Commodity/Enterprise/List`,
    params:{
        categoryId: params.categoryId,
        pageIndex:params.pageIndex,
        limit:params.limit,
    }
})
// 获取商品详情
export const getProductListShopDetails = (commodityID)=>request({
    method: 'get',
    url: `/Welfare/Shop/EnterpriseGoodInfo/${commodityID }`,
})
// 修改类型
export const getChangeType = (myCartId,type)=>request({
    method:'post',
    url:`/Welfare/MyCart/ChangeType/${myCartId}/${type}`
})
// 获取地址
export const getAddress = ()=>request({
    method:'get',
    url:'/Welfare/User/Address/List'
})
//获取省市区街道信息
export const getSelectCityLoad = (id)=>request({
    method:'get',
    url: `/Common/System/Area/${id}`
})
// 保存地址
export const saveAddress = (data)=>request({
    method:'post',
    url:'/Welfare/User/Address/AddOrUpdate',
    data:data
})
// 删除地址
export const delAddress =(id)=>request({
    method:'delete',
	url:`/Welfare/WeChat/Address/Delete/${id}`
})
// 提交订单 企业集采
export const getReqOrderEnterprie = (data) =>request({
    method:'post',
    url:'/Welfare/Order/Enterprie/Add',
    data:data
})
//获取首页 礼包列表 
export const getWelfareShopList = ()=>request({
    method:'get',
    url:'/Welfare/Shop/WelfareShopList'
})
//获取首页 商品列表
export const getGoodList =(categoryNum,goodNum)=>request({
    method:'get',
    url:`/Welfare/Mall/CategoryAndGood/List/${categoryNum}/${goodNum}`
})

//获取当前用户可使用优惠劵
export const getCoupon = (usedBusiness,terminalType) =>request({
    method:'get',
    url:`/Coupon/Center/CanUsedCoupon/${usedBusiness}/${terminalType}`
})

//获取用户是否签署节日福利协议
export const getWelfareStatus=()=>request({
    method:'get',
    url:'/UserProduct/GetStatus?type=7'
})

// 获取员工福利子首页统计
export const getStatistics=()=>request({
    method:'get',
    url:'/Welfare/HomePge/Statistics'
})

//为你推荐 与APP保持一至
export const getRecommendGoods=()=>request({
    method:'get',
    url:'/Welfare/Config/CommodityList?ParentId=10&IsActive=1&Limit=1000'
})

//常见问题 接口
export const getProblem=(keyWords)=>request({
    method:'get',
    url:`${apiUrl}/Banner/GetList?keyWords=${keyWords}&type=6&pageIndex=1&pageSize=12`
})

//
export const getSendScore=(taskId)=>request({
    method:'get',
    url:`/UserScore/MyScore/SendScore/${taskId}`
})

//获取 员工体检 套餐列表
export const getHealthMealList=(params)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/PCWelfare/MealList`,
    params
})
//获取 员工体检 套餐详情
export const getHealthMealInfo=(mealId)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/Meal/GetMealInfo?mealId=${mealId}`
})
//获取 员工体检 城市列表
export const getCityList=(cityName)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/PCWelfare/CityList`,
    params:{cityName}
})
//获取 员工体检 城市区域
export const getAreaList=(city)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/PCWelfare/AreaList?cityName=${city}`
})
//获取 员工体检 机构服务
export const getServiceList=()=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/PCWelfare/ServiceList`
})
//获取 员工体检 类型 1：套餐类别 2：高发疾病 3：适宜人群 4：年龄分组
export const getDataType=(dataType)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/PCWelfare/DataType/${dataType}`
})
//获取 员工体检 套餐列表
export const getMealList=(params)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/PCWelfare/MealList`,
    params
})
// ---体检机构 列表
export const getHospitalList=(params)=>request({
  method:'get',
  url:`${healthApi}/HealthCheck/PCWelfare/HospitalList`,
  params
})
// 体检机构--详情
export const getReqHospitalDeail=(params)=>request({
  method:'get',
  url:`${healthApi}/HealthCheck/PCWelfare/HospitalDeail`,
  params
})
// 体检机构-详情 所属套餐
export const getReqHospitalMealList=(params)=>request({
  method:'get',
  url:`${healthApi}/HealthCheck/PCWelfare/HospitalMealList`,
  params
})
// 排期
export const getReqHospSchedule=(params)=>request({
  method:'get',
  url:`${healthApi}/HealthCheck/PCWelfare/HospSchedule`,
  params
})
// 获取 员工体检 购物车
export const getHealthCart=()=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/Meal/GetMyCart`,
})

// 加入购物车 此接口登录那一刻只调用一次
export const getHealthReqAddCart = (data)=>request({
    method: 'post',
    url: `${healthApi}/HealthCheck/Meal/AddMyCart`,
    data
})

//员工体检 购物车添加
export const AddHealthCart=(data)=>request({
    method:'post',
    url:`${healthApi}/HealthCheck/Meal/AddMyCart`,
    data,
})
// 员工体检 修改购物车 数量
export const CountChange=(cartId,quantity)=>request({
    method:'get',
    url:`${healthApi}/HealthCheck/Meal/MyCart/CountChange?cartId=${cartId}&quantity=${quantity}`
})
// 员工体检 购物车删除
export const DeleteHealthCart=(cartId)=>request({
    method:'delete',
    url:`${healthApi}/HealthCheck/Meal/DeleteMyCart?cartId=${cartId}`
})

// 员工体检 提交购物车
export const SubmitHealthOrde=(data)=>request({
    method:'post',
    url:`${healthApi}/HealthCheck/Order/GenerateOrder`,
    data
})
//  获取当前城市
export const GetCurrentCity=()=>request({
    method:'get',
    url:`https://api.healthcheck.1renshi.com/HealthCheck/PCWelfare/GetCurrentCity`
})